import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import MessageLJPage from '../pagesComponents/messageLJPage';

const MessageLJ = ({ location }) => (
  <Layout location={location}>
    <MessageLJPage />
  </Layout>
);

MessageLJ.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default MessageLJ;
